<template>
  <b-form-group label="Cupon" label-for="cupon">
    <b-input-group>
      <b-form-input :value="cupon" @input="value => $emit('update:cupon', value)" placeholder="Write your cupon here!"
        :disabled="validCupon" />
      <b-input-group-append>
        <b-button variant="outline-danger" @click="cancelCupon" v-if="validCupon">X</b-button>
        <b-button variant="outline-success" @click="verifyCupon" v-else>Check</b-button>
      </b-input-group-append>
      <ToastNotification ref="toast" />
    </b-input-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BButton, BInputGroup, BFormInput } from "bootstrap-vue";
import ToastNotification from "@/components/ToastNotification";
import axios from "@/core/services/api/office-visit/index";

export default {
  components: {
    BButton,
    BInputGroup,
    BFormInput,
    ToastNotification,
    BFormGroup
  },
  props: {
    cupon: String
  },
  emits: ['update:cupon'],
  data() {
    return {
      validCupon: false,
    }
  },
  methods: {
    verifyCupon() {
      this.validCupon = false
      axios.verifyCupon({ cupon: this.cupon }).then(({ status, data }) => {
        if (status != "ok") return this.$refs.toast.danger("Error, cupon not found or expired!");
        this.validCupon = true
        this.$emit('cuponVerified', data)
        return this.$refs.toast.success("cupon found!");
      }).catch((e) => {
        this.$refs.toast.danger("Error, cupon not found or expired!");
      })
    },
    cancelCupon() {
      this.validCupon = false
      this.$emit('cancelCupon')
    }
  },
};
</script>

<style></style>