<template>
  <b-form-group label="membership" label-for="membership">
    <treeselect :value="membership" @input="value => $emit('update:membership', value)" :defaultOptions="true"
      :async="true" :load-options="searchMemberships" :normalizer="normalizer" />
    <ToastNotification ref="toast" />
  </b-form-group>
</template>

<script>
import { BFormGroup, BButton, BInputGroup, BFormInput } from "bootstrap-vue";
import ToastNotification from "@/components/ToastNotification";
import axios from "@/core/services/api/office-visit/index";

export default {
  components: {
    BButton,
    BInputGroup,
    BFormInput,
    ToastNotification,
    BFormGroup
  },
  props: {
    membership: Number,
    id_user: {
      required: true,
      default: 0,
      type: Number
    }
  },
  emits: ['update:membership'],
  data() {
    return {
      validmembership: false,
      normalizer(node) {
        return {
          id: node.id,
          label: node.memberships_parameters.name,
        };
      },
    }
  },
  methods: {
    searchMemberships({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        axios.verifyMembership(this.id_user).then(({ data }) => {
          callback(null, data);
        });
      }
    },
  },
};
</script>

<style></style>